const BannerLendersCP_V1= ['Bank of Scotland',
                            'Lloyds',
                            'Aqua',
                            'Capital One',
                            'Barclays Bank',
                            'Egg/Canada Square',
                            'Northern Rock/NRAM',
                            'Halifax',
                            'MBNA',
                            'Santander',
                            'Marks & Spencer',
                            'HSBC',
                            'TSB Bank',
                            'The Co-Operative Bank',
                            'Argos Card',
                            'Beneficial Bank',
                            'Express Gifts Ltd',
                            'Freemans',
                            'Grattan',
                            'JD Williams/N Brown',
                            'Monument Card',
                            'OPUS',
                            'Paragon Mortgages',
                            'Welcome'
                            ];
    const BannerYearAdv1= [];
    const start = 1933;
    const end = 2002;
    for (var i=start;i<=end;i++) {
        if(start === i){
            BannerYearAdv1.push([-i]);
        }else{
        BannerYearAdv1.push([i]);
        }
    }
export {BannerLendersCP_V1,BannerYearAdv1};