import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as EnvConstants from "../../constants/EnvConstants";
import { useYahooGeminiUrl } from "../../Hooks/useYahooGeminiUrl";

const YahooGemini = () =>{
    const queryString = useLocation().search;
    const { getYahooGeminiUrl } = useYahooGeminiUrl();

    useEffect(() => {
        (async () => {
            const visparam = {
                userAgent: window.navigator.userAgent,
                queryString: queryString,
                appEnv: EnvConstants.AppEnv
            };

            const responseYahooGeminiUrl = await getYahooGeminiUrl(
                visparam,
                window.location.href,
                EnvConstants.AppDomain
            );

            if (responseYahooGeminiUrl.data.status === "success") {
                window.location.href=responseYahooGeminiUrl.data.response + queryString;
            }
        })();     
    }, []);
    
    return (
        <>
        </>
    );
}

export default YahooGemini;
