import React,{useState} from 'react';
import "../../../../Assets/ADV_SQL_TXH_V1/ADV_SQL_TXH_V1Contact.scss"
import ADV_SQL_TXH_V1ContactFooter from "./ADV_SQL_TXH_V1ContactFooter";
import {useContact} from "../../../../Hooks/useContact";
const ADV_SQL_TXH_V1Contact     =   () =>{
    const {contactSubmit,constactLoading} = useContact();
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [messageValid, setMessageValid] = useState(false);
    const [contactSuccess, setContactSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(email === ""){
            setEmailValid(true);
        }else if(phone === "" || (phone.replace(/\D/g,'').length == 0)){
            setPhoneValid(true);
        }else if(message === ""){
            setMessageValid(true);
        }else{
            const txtPhone = phone.replace(/\D/g,'');
            try{
                const response      =   await contactSubmit(
                    phone,
                    email,
                    message
                )
                if(response.data.status === "Success"){
                    setTimeout(
                        () => setContactSuccess(true),
                        1000
                    );
                }
            }catch(e){
                console.warn(e);
            }
        }
    }
    const checkPhoneValid= (e) =>{
        var regex = new RegExp("^[0-9]+$");
        var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!regex.test(key)) {
            e.preventDefault();
            return false;
        }
    }
    return (
        <>
        <div className="ADV_SQL_TXH_V1Contact">
                <header className="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 contact_logo">
                                <a><img src="/ADV_SQL_TXH_V1/logo.png" className="animated  bounceInDown"/></a>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="contact_sec">
                    <div className="container">
                        <div className="row">
                            <div className="offset-lg-3 col-lg-6 col-12">
                                <div className="contact_area">
                                    <div className="col-lg-12 text-center col-12">
                                        <h1>Contact Us</h1>
                                    </div>
                                    <form name="contact_form" id="contact_form"  onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label >Email Address</label>
                                            <input type="email" name="emailid" id="emailid"
                                                   className="form-control h45" placeholder="Email Address"
                                                   onChange={(e)=>setEmail(e.target.value)}
                                                   value={email}  onBlur={()=>setEmailValid(false)}
                                            />
                                            <span id="email_err" className='error_msg' style={emailValid ? { display: "block" } : { display: "none" }}>Please Enter Email</span>
                                        </div>
                                        <div className="form-group">
                                            <label >Phone Number</label>
                                            <input type="text" name="phonenumber" id="phonenumber"
                                                   className="form-control h45" placeholder="Phone Number"
                                                   onChange={(e)=>setPhone(e.target.value)}
                                                   value={phone} onKeyPress={(e)=>checkPhoneValid(e)} onBlur={()=>setPhoneValid(false)} maxLength="11"
                                            />
                                            <span id="phone_err" className='error_msg' style={phoneValid ? { display: "block" } : { display: "none" }}>Please Enter Phone Number</span>
                                        </div>
                                        <div className="form-group ">
                                            <label >Message</label>
                                            <textarea className="form-control" rows="5" name="message" id="message"
                                                      placeholder="Message" onChange={(e)=>setMessage(e.target.value)} value={message}  onBlur={()=>setMessageValid(false)}>
                                    </textarea>
                                            <span id="message_err" className='error_msg' style={messageValid ? { display: "block" } : { display: "none" }}>Please Enter Message</span>
                                        </div>
                                        <div className="text-center">
                                            <input type="submit" id="contactbutton" className="btn btn-success send_btn center-block" value="Submit" value="Submit"/>
                                        </div>
                                        <span id="contact_success" className="contact_success" style={contactSuccess ? { display: "block" } : { display: "none" }}>Your email has been sent successfully</span>
                                    </form>
                                </div>
                            </div>

                            <div className="col-lg-12 addr text-center">
                                <p className="mt-4"> Savvy Finance </p>
                                <p> Suite LP26005,</p>
                                <p> 20-22 Wenlock Road,</p>
                                <p> London,</p>
                                <p> N1 7GU </p>
                            </div>
                        </div>
                    </div>
                </section>
            <ADV_SQL_TXH_V1ContactFooter></ADV_SQL_TXH_V1ContactFooter>
        </div>
    </>
    )
}
export default ADV_SQL_TXH_V1Contact;