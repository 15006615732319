import React from 'react';
import Anchor from "../../UI/Anchor";


const SVY_PCP_V1Header     =   ({heading,Style}) =>{
    const HeadString =  (heading)? heading: 'Advertorial';
    return (
        <>

          <header className="SVY_PCP_V1_header">
             <div class="container">
            <div class="row">
               <div class="col-lg-12 text-center d-none d-sm-none d-md-block d-lg-block">
                  <p class="adv_txt">{HeadString}</p>
               </div>
               <div class="col-lg-8 col-md-6 col-sm-6 col-5 logo">
                  <Anchor dataId="SITE_lOGO"><img src="/SVY_PCP_V1/img/logo.png" alt=""/></Anchor>
                  <p class="adv_txt d-block d-sm-block d-md-none d-lg-none m-0">{HeadString}</p>
               </div>
               <div class="col-lg-4 col-md-6  col-sm-6 col-7 text-right hdright_sec">
                  <Anchor className="btn top_btn" dataId="TOP_CTA_BTN"> CHECK YOUR ELIGIBILITY
                  </Anchor>
               </div>
            </div>
         </div>
         </header>


    </>
    )
}
export default SVY_PCP_V1Header;