import React from 'react';
import Anchor from "../../UI/Anchor";

const SVY_HDR_V1Header     =   ({heading,Style}) =>{
    const HeadString =  (heading)? heading: 'Advertorial';
    return (
        <>
         <header className="SVY_HDR_V1_header">
            <div className="container">
               <div className="row">
                  <div class="col-lg-12 text-center">
                     <p class="adv_txt">{HeadString}</p>
                  </div>
                  <div class="col-lg-8 col-md-6 col-sm-6 col-5 logo">
                     <Anchor dataId="SITE_LOGO"> 
                        <img src="/SVY_HDR_V1/img/logo.png" alt="Logo"/> 
                     </Anchor>
                  </div>
                  <div class="col-lg-4 col-md-6  col-sm-6 col-7 text-right hdright_sec">
                     <Anchor dataId="TOP_CTA_BTN" className="btn top_btn" children="Check Your Eligibility"></Anchor>
                  </div>
               </div>
            </div>
         </header>
    </>
    )
}
export default SVY_HDR_V1Header;