import React, { useState } from "react";
const SVS_GLI_ADV1Footer = ({ logo }) => {
    const [showPrivacy,setShowPrivacy] = useState('hide');
    const [showTerms,setShowTerms] = useState('hide');
    const modalClick = (clickValue) => {
        var modalAction = clickValue;
        switch (modalAction){
            case'showPrivacy':{
                setShowPrivacy('show');
                document.body.classList.add('modal-open');
                break;  
            }
            case'exitPrivacy':{
                setShowPrivacy('hide');
                document.body.classList.remove('modal-open');
                break;
              }
              case'showTerms':{
                setShowTerms('show');
                document.body.classList.add('modal-open');
                break;
              }
              case'exitTerms':{
                setShowTerms('hide');
                document.body.classList.remove('modal-open');
                break;
              }
        }
    }
    return (
        <>
            <div className="container-fluid margin-12">
                <div className="row row_margin">
                    <div className="col-12 text-center">
                        <a href="#">
                            <img src={logo} alt="" className="img-fluid mb-2 img-responsive" />
                        </a>
                        <p className="small">Veriform ltd and is authorised and regulated by the FCA.</p>
                        <p className="small">Subject to our terms and conditions The Quoter Network will process the information you provide us and may pass this on to our named partners for marketing purposes.</p>
                        <p className="small">Veriform Ltd is a company registered in England & Wales, registration number 07195400 and registered at 145/147 Hatfield Road, St. Albans, Hertfordshire, AL1 4JY, Authorised and regulated by the Financial Conduct Authority, firm reference number 690199.</p>
                        <p className="small">Savvy Sam is not an insurance company nor able to offer any advice into any financial services regulated products. Any terms and conditions may vary for all of our partners.</p>
                        <p className="small">We do not charge a fee for this service, but we do receive a commission directly from our partners following successful introductions from this website. Any information we provide is for the purposes of illustrating the products promoted on this site only and should not be read as financial advice.
                        </p>
                        <p className="small">Most of our life assurance clients compare different products on your behalf. However, some only provide a quote on their own product.</p>

                        <ul className="links pt-2">
                            <li>
                                {/* <a href="#prvcy" data-bs-toggle="modal" data-bs-target="#prvcy">
                                    Privacy
                                </a> */}
                                <a className="rp_clk" data-toggle="modal"
                                        onClick={()=>modalClick('showPrivacy')}>Privacy</a>

                            </li>
                            <li>|</li>
                            <li>
                                {/* <a href="#terms" data-bs-toggle="modal" data-bs-target="#terms">
                                    Terms & Conditions
                                </a> */}
                                <a className="rp_clk" data-toggle="modal"
                                        onClick={()=>modalClick('showTerms')}> Terms & Conditions</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${showTerms}`} id="terms" tabIndex="-1" aria-labelledby=" " aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id=" ">Terms and Conditions</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>modalClick('exitTerms')}>
                            <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Savvy Sam is an online referral and introducer of FCA regulated Life Insurance products. We provide a referral service to named regulated companies for the processing of claims based on the information provided through this website.
                            </p>
                            <p>
                                The terms 'Savvy Sam', 'us' or 'we' refers to the owner of the website, Veriform Ltd. The term 'you' refers to the user or viewer of our website. The use of this website is subject to the following terms of use:
                            </p>
                            <ol className="terms-list">
                                <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.
                                </li>
                                <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                                </li>
                                <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
                                </li>
                                <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
                                </li>
                                <li>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.
                                </li>
                                <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.
                                </li>
                                <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                                </li>
                                <li>You may not create a link to this website from another website or document without Savvy Sam or Veriform Ltd's prior written consent.
                                </li>
                            </ol>
                            <p>
                                Veriform ltd is a company registered in England & Wales, registration number 07195400 and registered address at 145/147 Hatfield Road, St. Albans, Hertfordshire, AL1 4JY, and is authorised and regulated by the Financial Conduct Authority, firm reference number 690199.
                            </p>
                            <p>Veriform Ltd or any of its trading styles are not an insurance company nor able to offer any advice into any financial services regulated products. Any terms and conditions may vary for all our partners.</p>
                            <p>We do not charge a fee for this service, but we do receive referral fee directly from our partners following successful introductions from this website. Any information we provide is for the purposes of illustrating the products promoted on this site only and should not be read as financial advice.</p>
                            <p>
                                Any complaints that arise from contact originating from communication from a named company or third party on this site is the responsibility of Veriform Ltd should be communicated via our complaint procedure stated on our site.
                            </p>
                            <p>Any acceptance of a third party's terms and conditions or privacy policy is not accordance to Veriform Ltd and independent advice is recommended before entering any third-party terms and conditions or privacy policies.</p>
                            <p>
                                While yourlife-insured.co.uk has taken care in the preparation of the contents of this website, this website and the information, names, images, pictures, logos, icons regarding or relating to yourlife-insured.co.uk or the products and services of the same (or to third party products and services), are provided on an 'as is' basis without any representation or endorsement being made and without any warranty of any kind, whether express or implied, including but not limited to, any implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy. To the extent permitted by law, all such terms and warranties are hereby excluded. In no event will yourlife-insured.co.uk be liable (whether in contract or tort (including negligence or breach of statutory duty) or otherwise) for any losses sustained and arising out of or in connection with use of this website including, without limitation, loss of profits, loss of data or loss of goodwill (in all these cases whether direct or indirect) nor any indirect, economic, consequential or special loss.
                            </p>
                            <p>
                                Last Updated: May 2022
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={()=>modalClick('exitTerms')}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className={`modal fade ${showPrivacy}`} id="prvcy" tabIndex="-1" aria-labelledby=" " aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id=" ">Privacy Policy</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>modalClick('exitPrivacy')}>
                            <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="">
                                <div className="blue-grid ppcontent">
                                    <h3>1. Background</h3>
                                    <p>This Privacy Policy explains how we use your personal data: how it is collected, how it is held, and how it is processed. It also explains your rights under the law relating to your personal data.</p>
                                    <p>Veriform Ltd ("We", "Us", "Our") understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of all our customers and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.</p>
                                    <p> You have certain rights in relation to your data, including the right to object to processing based on legitimate interests, profiling, and direct marketing. You can exercise your rights at any time by emailing us at info@veriform.co.uk. For further details about our processing and your rights, please see below.</p>

                                    <h3 className="py-2">2. How Our Website Works</h3>
                                    <p> If you'd like to request and receive quotes or information for any of our products, you will need to complete and submit the form on any of our websites. </p>
                                    <p>Once we've received your request, we'll connect you with one or more relevant Life Insurance Clients (depending on the specific permissions you have given us). We'll tell the Client who you are, and your product requirements, and they'll then contact you directly to discuss your requirements and potentially provide you with a quote.</p>
                                    <p>You'll see on any of our websites that we have a clear consent statement. This statement sets out the Life Insurance Clients that will contact you.</p>
                                    <p>You can choose which Life Insurance Clients to buy from but you are not obliged to purchase from any of them.</p>
                                    <p>We don't work with any Life Insurance Clients until we have a legal contract in place and have completed a detailed due diligence process. This is to ensure that they respect your data and rights by keeping your data secure and confidential, limiting their use of your data (i.e. to providing you with the requested information), and not sharing your data with anyone else.</p>

                                    <h3 className="py-2">3. Information About Veriform Ltd</h3>
                                    <p>Limited Liability Company registered in England under company number 07195400.</p>
                                    <p>Registered address: 145/147 Hatfield Road, St. Albans, Hertfordshire, AL1 4JY.</p>
                                    <p>Main trading address: Spaces, The Maylands Building, Hemel Hempstead, Hertfordshire, HP2 7TG</p>
                                    <p>Email address: info@veriform.co.uk</p>
                                    <p>Telephone number: 01442 954 740</p>
                                    <p>ICO Registration Number: Z2350000</p>
                                    <p>FCA Authorisation Number: 690199</p>

                                    <h3 className="py-2">4. What Does This Notice Cover?</h3>
                                    <p>This Privacy Information explains how we use your personal data: how it is collected, how it is held, and how it is processed. It also explains your rights under the law relating to your personal data.</p>

                                    <h3 className="py-2">5. What Is Personal Data?</h3>
                                    <p>Personal data is defined by the UK General Data Protection Regulation (the UK GDPR) and the Data Protection Act 2018 (the DPA 2018) as 'any information relating to an identifiable person who can be directly or indirectly identified by reference to an identifier'.</p>
                                    <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.</p>
                                    <p>The personal data that we use is set out in Part 7, below.</p>

                                    <h3 className="py-2">6. What Are My Rights?</h3>
                                    <p>Under the UK GDPR and the DPA 2018 you have the following rights, which we will always work to uphold:</p>
                                    <p>
                                        <ul>
                                            <li>The right to be informed about our collection and use of your personal data. This Privacy Notice should tell you everything you need to know, but you can always contact us to find out more or to ask any questions using the details in Part 14.</li>
                                            <li>The right to access the personal data we hold about you. Part 13 will tell you how to do this.</li>
                                            <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in Part 14 to find out more.</li>
                                            <li>The right to erasure, i.e., the right to ask us to delete or otherwise dispose of any of your personal data that we have, subject to terms and conditions. Please contact us using the details in Part 14 to find out more.</li>
                                            <li>The right to restrict (i.e., prevent) the processing of your personal data.</li>
                                            <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
                                            <li>The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask for a copy of that personal data to re-use with another service or business in many cases.</li>
                                            <li>Rights relating to automated decision-making and profiling. We do not use your personal data in this way.</li>
                                        </ul>
                                    </p>
                                    <p>For more information about our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in Part 14. </p>
                                    <p>Further information about your rights can also be obtained from the Information Commissioner's Office or your local Citizens Advice Bureau.</p>
                                    <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner's Office. You can find the relevant contact details and the best way to make a complaint to the ICO at www.ico.org.uk.</p>

                                    <h3 className="py-2">7. What Personal Data Do You Collect?</h3>
                                    <p>In order to provide you with our service we pass your details on to one of our Life Insurance Clients so they can contact you by phone, email or SMS and provide you with more information or a quote for Life Insurance. Alternatively, Veriform Ltd may phone, email or SMS you to pre-qualify your requirements before transferring the call through to our Client. We do not knowingly, deliberately or aim to, collect personal data from children under the age of 18, either for registration or for marketing purposes. If you are 18 or over, we may collect some or all of the following personal data (this may vary according to your relationship with us). From time to time, we may change our websites, and so they may ask you for other information. It will always be clear from the website what information we're requesting, and we only request information that is relevant to your request, and the service that we're providing</p>
                                    <p>
                                        <ul>
                                            <li>Full name</li>
                                            <li>Title</li>
                                            <li>Date of birth</li>
                                            <li>Gender</li>
                                            <li>Postal Address</li>
                                            <li>Email address</li>
                                            <li>Telephone number</li>
                                            <li>Information about your preferences and interests</li>
                                            <li>Demographic information</li>
                                            <li>Your IP Address. We store IP addresses in order to stop unwanted traffic (i.e. spam) to our website. For example, if we become aware that spam is coming from a particular IP address (i.e. computer or location), we can block that IP address.</li>
                                            <li>Cookies</li>
                                        </ul>
                                    </p>
                                    <p>We do not knowingly, deliberately or aim to, collect personal data from children under the age of 18, either for registration or for marketing purposes.</p>
                                    <p>We collect your personal data in a number of ways:</p>
                                    <p>(a) When you fill in a website form on any of our websites.</p>
                                    <p>(b) When you write to us to enter a competition, prize-draw, or make an information request;</p>
                                    <p>(c) When you provide personal data on any area of our websites;</p>
                                    <p>(d) When you get in touch with us by email, social media, telephone, writing, and any other means;</p>
                                    <p>(e) When you subscribe to our services to receive communications from us about products and services e.g., by a newsletter, through social media etc.; and</p>
                                    <p>(f) Participate in a survey, market research, competition and/or or prize-draw on any of our websites.</p>

                                    <h3 className="py-2">8. How Do You Use My Personal Data?</h3>
                                    <p>Under the UK GDPR and the DPA 2018, we must always have a lawful basis for using personal data. This may be because the data is necessary for our performance of a contract with you, because you have consented to our use of your personal data, or because it is in our legitimate business interests to use it. We collect for personal information in order to provide you with our service and pass your details on to one of our Life Insurance Clients so they can contact you by phone, email or SMS and provide you with more information or a quote for Life Insurance. Alternatively, Veriform Ltd may phone, email or SMS you to pre-qualify your requirements before transferring the call through to our Client. The information we hold is held securely within an encrypted database and we will only ever transfer your data in a secure way.</p>
                                    <p>We take the protection of this data very seriously and will only routinely share data via secure systems with relevant Life Insurance Clients, to progress and conclude enquiries. We may, on occasion, also be legally required to share data with regulatory bodies, including the Ministry of Justice, the Financial Ombudsman Service, the Legal Ombudsman and the Information Commissioner's Office.</p>
                                    <p>Your personal data may be used for one of the following purposes under a legitimate interest or consent basis. The method of communication will be by phone, email or SMS. The method of communication will be determined by the consent you have provided.</p>
                                    <p>To manage your registration on our websites;</p>
                                    <p>Determine whether any of the Life Insurance Clients or approved partners we work with may have services, products and offers that may be of interest to you based on the preferences and permissions you have supplied to us; and</p>
                                    <p>With consent, communicate your data to our trusted partners and Life Insurance Clients. You may object to this processing at any time by contacting us on the details provided in Part 14 below.</p>
                                    <p>Improve and personalise our website to you, and this could be where we may market directly to you, about other relevant offers that may be of interest to you based on your preferences;</p>
                                    <p>Depending on the consents that you have provided and what personal data we hold about you, we may pass your personal data to website sponsors or brand partners (third parties) who may contact you by phone, email or SMS for direct marketing purposes.</p>
                                    <p>For analysis purposes. Where this is done, the information is aggregated and wherever possible anonymised in line with the ICO's code of practice. These products are of a marketing nature. </p>
                                    <p>We may process your personal data ourselves or through third party data processors that will process data on our behalf. We ensure the data processor complies with this policy and all current data protection regulations.</p>
                                    <p>Your information will only be released to third parties where you have given your explicit consent, at the time of registering your interest on any of our websites allowing you to fully understand what you are consenting to.</p>
                                    <p>If you consent to a call from one of our sponsors or brand partners this will override the TPS.</p>
                                    <p>The legitimate interests can be your interests or our interests. They can include commercial interests, individual interests or broader societal benefits but we need to be able to identify a legitimate interest; show that the processing is necessary to achieve it; and balance it against your interests, rights and freedoms.</p>
                                    <p>With your permission and/or where permitted by law, we may also use your personal data for marketing purposes, which may include contacting you by phone, email or SMS with information, news, and offers on our products and services. You will not be sent any unlawful marketing or spam. We will always work to fully protect your rights and comply with our obligations under the UK GDPR, the DPA 2018, and the Privacy and Electronic Communications (EC</p>
                                    <p>Directive) Regulations 2003, and you always have the opportunity to opt-out.</p>
                                    <p>We only ever send personal information to 3rd parties where you have given consent to do so. To prevent fraud and to ensure data accuracy it is necessary to submit your information for verification and filtering. This enables us to provide our services to you, and fulfil our legal obligations. We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information. We will store all the personal information you provide on our secure servers that are subject to strict security requirements.</p>
                                    <p>From time to time, Service Providers may return data to us. They may do this if they have a query regarding a request for Quotes that we have sent to them in order to enable us to resolve the query. They may also return data to us, together with confirmation on whether or not a product has been purchased, in order to enable us to improve our marketing.</p>

                                    <h3 className="py-2">9. How Long Will You Keep My Personal Data?</h3>
                                    <p>We have a data retention policy, which clearly sets out how long we keep data for, and for what reasons. We will not keep your personal data for any longer than is necessary in light of the reasons for which it was first collected. Your personal data will therefore be kept for the following periods:</p>
                                    <p>We will keep your contact details following an enquiry to any of our websites for a period of 6 months. After 6 months your personal information is placed in a suppression file for a further 18 months. We need to do this to ensure you do not receive any further communication from us or from any of our partners if your information has been supplied to them previously.</p>
                                    <p>This enables us to provide our services to you, and fulfil our legal obligations. We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information. We will store all the personal information you provide on our secure servers that are subject to strict security requirements.</p>

                                    <h3 className="py-2">10. What Are Cookies And How Do We Use Them?</h3>
                                    <p>Some of our websites may use cookies to collect information. You will be given a Cookie notification bar when you first visit any of our sites where your permission will be requested if that particular website uses Cookies. Cookies are small data files which are placed on your computer or other mobile or handheld device (such as smart 'phones or 'tablets'), as you browse our websites. They are used to 'remember' when your computer or device accesses our websites. We do not use cookies to collect or record information on users' name, address or other contact details. The cookies stored on your computer or other device when you access our websites are set by us and other third parties. Cookies may also be set by third parties who we use for marketing. None of these third parties collect any personal data from which they would be able to identify individual customers. The main purposes for which cookies are used are:</p>

                                    <p>For technical purposes essential to effective operation of websites.</p>

                                    <p>To drive marketing, particularly banner advertisements and targeted updates.</p>

                                    <p>How do I disable Cookies?</p>

                                    <p>If you want to disable cookies you need to change your website browser settings to reject cookies. How to do this will depend on the browser you use, and we provide further detail below on how to disable cookies for the most popular browsers:</p>

                                    <p>For Microsoft Internet Explorer:</p>

                                    <p>Choose the menu "tools" then "Internet Options"</p>

                                    <p>lick on the "privacy" tab</p>

                                    <p>Select the setting the appropriate setting</p>

                                    <p>For Mozilla Firefox:</p>

                                    <p>Choose the menu "Tools" then "Options"</p>

                                    <p>Click on the icon "Privacy"</p>

                                    <p>Find the menu "Cookie" and select the relevant options</p>

                                    <p>For Opera 6.0 and further:</p>

                                    <p>Choose the menu "Files"  "Preferences"</p>

                                    <p>Privacy</p>

                                    <p>What will happen if I disable Cookies?</p>

                                    <p>This depends on which cookies you disable. However, in general the website may not operate effectively if cookies are deactivated.</p>

                                    <h3 className="py-2">11. How And Where Do You Store Or Transfer My Personal Data?</h3>
                                    <p>
                                        We will only store or transfer your personal data within the European Economic Area (the "EEA"). The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein. This means that your personal data will be fully protected under the UK GDPR and the DPA 2018 or to equivalent standards by law.
                                    </p>

                                    <h3 className="py-2">12. Do You Share My Personal Data?</h3>
                                    <p>
                                        We will only share some or all of your personal data with select third parties. We will always ask for your consent before doing so and this is entirely your choice.
                                    </p>
                                    <p>In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>

                                    <p>If any of your personal data is required by a third party, as described above, we will take steps to ensure that your personal data is handled safely, securely, and in accordance with your rights, our obligations, and the third party's obligations under the law, as described above in Part 8.</p>

                                    <p><b>Our Life Insurance Clients;</b><br />
                                        Enduralife Ltd - Company number 08264595
                                        Protect Line Ltd - Company Number 7059779</p>

                                    <p><b>Our Data Processing Partners;</b><br />
                                        The Read Group
                                        Liquid 11
                                        Brite Verify
                                        Data8 Ltd</p>


                                    <h3 className="py-2">13. How Can I Access My Personal Data?</h3>
                                    <p>If you want to know what personal data that we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a "subject access request".</p>

                                    <p>All subject access requests should be made in writing and sent to the email or postal addresses shown in Part 14. To make this as easy as possible for you, a Subject Access Request Form is available for you to use. You do not have to use this form, but it is the easiest way to tell us everything we need to know to respond to your request as quickly as possible.</p>

                                    <p>There is not normally any charge for a subject access request. If your request is 'manifestly unfounded or excessive' (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.</p>

                                    <p>We will respond to your subject access request within 28 days and, in any case, not more than one month of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time.</p>

                                    <p>In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request.</p>

                                    <p>You will be kept fully informed of our progress.</p>


                                    <h3 className="py-2">14. How Do I Contact You?</h3>
                                    <p>To contact us about anything to do with your personal data and data protection, including to make a subject access request, please use the following details (for the attention of): Data Protection Officer Main trading address: Spaces, The Maylands Building, Hemel Hempstead, Hertfordshire, HP2 7TG
                                    </p>
                                    <p>Email address: info@veriform.co.uk</p>

                                    <p>Telephone number: 01442 954 740</p>


                                    <h3 className="py-2">15. Changes To This Privacy Notice</h3>
                                    <p>We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.</p>
                                    <p>Any changes will be made available by posting a new version on our website. You should check this page occasionally to ensure you are happy with any changes, although anything that will affect you will be communicated directly to you.</p>


                                    <h3 className="py-2">16. Copyright</h3>
                                    <p>You may download, print extracts and/or make copies of works on any of our websites for your own personal and non-commercial use, providing you acknowledge Veriform Ltd as the source of the information and include a link to our website.</p>
                                    <p>Last Updated: May 2022</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={()=>modalClick('exitPrivacy')}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SVS_GLI_ADV1Footer;