import React from "react";
import { BrowserRouter,Route } from "react-router-dom";
import SVS_GLI_ADV1 from "./Components/Pages/SVS_GLI_ADV1";
import SVS_GLI_Male_ADV2 from "./Components/Pages/SVS_GLI_Male_ADV2";
import ADV_SQL_TXH_V1 from "./Components/Pages/ADV_SQL_TXH_V1";
import ADV_GNS_TXH_V1 from "./Components/Pages/ADV_GNS_TXH_V1";
import SF_TXH_V1 from "./Components/Pages/SF_TXH_V1";
import SF_GNS_V1 from "./Components/Pages/SF_GNS_V1";
import ADV_SQL_TXH_YG_V1 from "./Components/Pages/ADV_SQL_TXH_YG_V1";
import SF_TXH_YG_V1 from "./Components/Pages/SF_TXH_YG_V1";
import SVY_HDR_V1 from "./Components/Pages/SVY_HDR_V1";
import SVY_HDR_V1Contact from "./Components/Includes/SVY_HDR_V1/Contact/SVY_HDR_V1Contact";
import SVY_PCP_V1 from "./Components/Pages/SVY_PCP_V1";
import SVY_PCP_V1Contact from "./Components/Includes/SVY_PCP_V1/Contact/SVY_PCP_V1Contact";
import Contact from './Components/Includes/SF_TXH_V1/Contact/SF_TXH_V1Contact'
import ADV_SQL_TXH_V1Contact from "./Components/Includes/ADV_SQL_TXH_V1/Contact/ADV_SQL_TXH_V1Contact";
import FbPixel from './Components/Pages/FbPixel';
import YahooGemini from './Components/Pages/YahooGemini';
import GoogleTag from './Components/Includes/GoogletagManager';
import FbViewPixel from './Components/Includes/FbViewPixel';
import Matomo from "./Components/Includes/Matomo";
import * as EnvConstants from "../src/constants/EnvConstants";
import "./App.scss";

const App = () => {
  return (
    <>
     {EnvConstants.AppEnv==='prod'
        ? <Matomo/>
        : ''
      }
      <GoogleTag/>
      <FbViewPixel/>
      <BrowserRouter>
        <Route exact path="/fbpixel" component={FbPixel} />
        <Route exact path="/yahoo-gemini" component={YahooGemini} />
        <Route exact path={["/SVS_GLI_ADV1"]} component={SVS_GLI_ADV1} />
        <Route exact path={["/SVS_GLI_Male_ADV2"]} component={SVS_GLI_Male_ADV2} />
        <Route exact path={["/","/MarriageTax/ADV_SQL-TXH_V1"]} component={ADV_SQL_TXH_V1} />
        <Route exact path={["/MarriageTax/ADV_SQL_GNS-_V1"]} component={ADV_GNS_TXH_V1} />
        <Route exact path={["/MarriageTax/ADV_SQL-TXH_V1/contact-us"]} component={ADV_SQL_TXH_V1Contact} />
        <Route exact path={["/MarriageTax/ADV_SQL_GNS-_V1/contact-us"]} component={ADV_SQL_TXH_V1Contact} />
        <Route exact path={["/2021/MarriageTax_SF_TXH_V1"]} component={SF_TXH_V1} />
        <Route exact path={["/2021/MarriageTax_SF_GNS_V1"]} component={SF_GNS_V1} />
        <Route exact path={["/2021/MarriageTax_SF_TXH_YG_V1"]} component={SF_TXH_YG_V1} />
        <Route exact path={["/MarriageTax/ADV_SQL-TXH_YG_V1"]} component={ADV_SQL_TXH_YG_V1} />
        <Route exact path={["/2021/MarriageTax_SF_TXH_V1/contact-us"]} component={Contact} />
        <Route exact path={["/2021/MarriageTax_SF_GNS_V1/contact-us"]} component={Contact} />
        <Route exact path={["/2021/10/HouseRepair/SVY_HDR_V1"]} component={SVY_HDR_V1} />
        <Route exact path={["/2021/10/HouseRepair/SVY_HDR_V1/contact-us"]} component={SVY_HDR_V1Contact} />
        <Route exact path={["/2021/11/Reclaim4U/SVY_PCP_V1"]} component={SVY_PCP_V1} />
        <Route exact path={["/2021/11/Reclaim4U/SVY_PCP_V1/contact-us"]} component={SVY_PCP_V1Contact} />
      </BrowserRouter>
    </>
  );
};
export default App;
