import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sass from "../../Assets/SVY_PCP_V1/SVY_PCP_V1.scss";
import { VisitorParamsProvider } from "../../Contexts/VisitorParams";
import { RedirectProvider } from "../../Contexts/RedirectContext";
import * as EnvConstants from "../../constants/EnvConstants";
import { useUserAgent } from "../../Hooks/useUserAgent";
import { useRedirection } from "../../Hooks/useRedirection";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import SVY_PCP_V1Header from "../Includes/SVY_PCP_V1/SVY_PCP_V1Header";
import SVY_PCP_V1Footer from "../Includes/SVY_PCP_V1/SVY_PCP_V1Footer";
import Anchor from "../UI/Anchor";
import { useHistory } from "react-router-dom";
import { UUID } from '../Includes/UUID';

const SVY_PCP_V1 = () => {
  const queryString = useLocation().search;
  const [redirectUrl, setRedirectUrl] = useState("#");
  const [visitorParameters, setVisitorParameters] = useState({});
  const [currentMonth, setCurrentMonth] = useState("");
  const { getUserAgent } = useUserAgent();
  const { getRedirectionUrl } = useRedirection();
  const { saveDataIngestion } = useDataIngestion();
  const { getUUID, setExpiry, setParams } = UUID();
  const [region, setRegion] = useState("");
  const history = useHistory();
  setExpiry();
  let date = new Date();
  let Month = date.toLocaleString("en-us", { month: "long" });
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  const local_storage_uuid = localStorage.getItem('uuid');
  let local_storage_adv_name = localStorage.getItem('adv_name');
  var check_uuid = "";
  if (typeof query_string_arr[1] !== "undefined") {
    const query = new URLSearchParams(window.location.search);
    check_uuid = query.get("uuid");
  }
  useEffect(() => {
    setCurrentMonth(Month);
    (async () => {
      const uuids = getUUID(
        check_uuid,
        local_storage_uuid,
        local_storage_adv_name,
        '2021/11/Reclaim4U/SVY_PCP_V1'
      );
      const response = await getUserAgent(
        window.navigator.userAgent,
        "AP",
        queryString,
        EnvConstants.AppDomain,
        window.location.href,
        uuids
      );
      const visitorData = response.data.response.visitor_parameters;
      const DataParams = response.data.response.data;
      const uuid = visitorData.uuid;

      setRegion(visitorData.region)
      setParams(uuid, '2021/11/Reclaim4U/SVY_PCP_V1')

      setVisitorParameters({
        visitor_parameters: visitorData,
        data: DataParams,
        date: new Date(),
        pageName: "SVY_PCP_V1",
      });
      const visparam = {
        country: DataParams.country,
        device: DataParams.site_flag_id,
        siteFlagId: DataParams.site_flag,
        browser: DataParams.browser,
        platform: DataParams.platform,
        uuid: visitorData.uuid,
      };
      const responseRedirect = await getRedirectionUrl(
        visparam,
        window.location.href,
        EnvConstants.AppDomain,
        "SVY_PCP_V1"
      );
      await saveDataIngestion(
        "adv_page_load",
        visitorData,
        DataParams,
        "SVY_PCP_V1"
      );
      if (responseRedirect.data.status === "success") {
        setRedirectUrl(responseRedirect.data.response.data.redirect_url);
      } else {
        setRedirectUrl("#");
      }
    })();
  }, []);

  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <RedirectProvider value={{ redirectUrl: redirectUrl }}>
            <SVY_PCP_V1Header heading="" />
            <section className="SVY_PCP_V1_section">
              <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <h1>
                     Did You Finance A £30,000+ Vehicle in the Last 6 Years? You Could Be Owed Thousands!
                  </h1>
                  <h3>
                     Find out if you’re owed compensation today.
                  </h3>
                  <Anchor dataId="PARA_IMAGE_1">
                  <img src="/SVY_PCP_V1/img/banner.jpg" alt="" class="banner_image"/>
                  </Anchor>
                  <p class="banner_txt">
                     <span>
                     <Anchor dataId="PARA_TEXT_LINKS_1">
                     <u>
                     If you financed a £30,000+ vehicle in the last 6 years it is quite possible you were mis-sold 
                     PCP and may be eligible to receive compensation.                 
                     </u>
                     </Anchor>
                     </span>
                  </p>
                  <p>
                     In March 2019 the Financial Conduct Authority (FCA) discovered evidence of mis-selling on vehicle 
                     finance options.  Brokers and dealers misled thousands of car owners by mis-selling PCP deals. <strong>The FCA 
                     reported over 560,000 consumers paid 50% more on their car finance deals than they needed to!*</strong> 
                  </p>
                  <div class="col-lg-12 blue_div">
                     <h3>What is PCP?</h3>
                     <p>Personal Contract Purchase (PCP) is a vehicle financing agreement which involves 
                        the consumer paying lower monthly payments followed by a final lump sum. 
                        It is essentially an interest-only loan for the vehicle and the interest charges
                        are typically more expensive than the consumer anticipates. 
                     </p>
                  </div>
                  <p>
                     The FCA found that brokers were secretly increasing the interest rates for PCP deals 
                     resulting in higher commission payments for the car dealerships.  Consumers 
                     were being overcharged by up to £1,100 per PCP deal!
                  </p>
                  <p>
                     <strong>
                     <Anchor dataId="PARA_TEXT_LINKS_1">
                     <u>
                     If you purchased a £30,000+ vehicle in the last 6 years and believe you 
                     were mis-sold a PCP deal you could be owed thousands! 
                     </u>
                     </Anchor>
                     </strong>
                  </p>
               </div>
               <div class="col-lg-12 col-12  banner text-center">
                  <div class="mid-banner">
                     <div class="midinnr">
                        <div class="triangle1"></div>
                        <h2> TAP THE VALUE OF YOUR VEHICLE TO START A CLAIM </h2>
                        <Anchor dataId="MID_PARA_NUM">-£25,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£25,000-£28,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£28,000-£31,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£31,000-£34,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£34,000-£37,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£37,000-£40,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£40,000-£43,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£43,000-£46,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£46,000-£49,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£49,000-£52,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£52,000-£55,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£55,000-£58,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£58,000-£61,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£61,000-£64,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£64,000-£67,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£67,000-£70,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£70,000-£73,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£73,000-£76,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£76,000-£79,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£79,000-£82,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£82,000-£85,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£85,000-£88,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£88,000-£91,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£91,000-£94,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£94,000-£97,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£97,000-£100,000</Anchor>
                        <Anchor dataId="MID_PARA_NUM">£100,000+</Anchor>
                     </div>
                  </div>
               </div>
               <div class="col-lg-12">
                  <h3>Do I Qualify to Claim?</h3>
                  <ul>
                     <li>Did you fully understand the finance deal offered to you?</li>
                     <li>Were you made aware of other options available to you?</li>
                     <li>Did the salesperson tell you what commission they were receiving? </li>
                  </ul>
                  <p>
                     If you answered ‘No’ to one or more of the above questions, you may be eligible to make a 
                     claim and receive hundreds or even thousands in compensation.   Checking to see if you’re 
                     eligible to claim is easy and you are under no obligation to proceed. <Anchor dataId="PARA_TEXT_LINKS_1"><strong><u>Start your for 
                     FREE online claim today</u></strong></Anchor>.
                  </p>
               </div>
               <div class="col-lg-12 maptxt_sec">
                  <h2>How To Get Started</h2>
                  <p>
                     <strong>Step 1:</strong>  Simply select your country on the map below to get started. 
                  </p>
                  <p class="mb-0">
                     <strong>Step 2:</strong> Answer a few questions on our easy online claims form.  
                     One of our trusted representatives will review your details to assess if you’re eligible <u>FREE</u> of charge. 
                  </p>
               </div>
               <div class="col-lg-12 map_sec">
                  <Anchor dataId="MAIN_CTA_MAP">
                  <img src="/SVY_PCP_V1/img/map.png" alt=""/>
                  </Anchor>
               </div>
               <div class="col-lg-12 text-center">
                  <Anchor className="btn main_ctabtn" dataId="MAIN_CTA_BTN">
                  CHECK YOUR ELIGIBILITY
                  </Anchor>
               </div>
            </div>
         </div>
            </section>
            <SVY_PCP_V1Footer/>
        </RedirectProvider>
      </VisitorParamsProvider>
    </>
  );
};
export default SVY_PCP_V1;