import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import {getParameterByName} from "../../Utility/Helper";

const FbPixel = () =>{

    const full_url = window.location.href;
    const query_string_arr = full_url.split("?");
    var queryString = "";
    if(query_string_arr!=null)
    {
         queryString = query_string_arr[1];
    }

    const field = 'pid';
    const field2 = 'redirect_url';
    if(queryString!=null)
    {
        if(queryString.indexOf('&' + field2 + '=') !== -1)
        {
            const redirect_url = getParameterByName(field2,queryString);
            if(queryString.indexOf('&' + field + '=') !== -1)
            { 
                const pid = getParameterByName(field,queryString);
                const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
                const options = {
                autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                debug: false, // enable logs
                };
                ReactPixel.init(pid, advancedMatching, options);
                ReactPixel.pageView(); 
                ReactPixel.track('Lead');
                window.location.href=redirect_url+"?"+queryString;    
            }
        }
    }
    
    return (
        <>
        </>
    )
}

export default FbPixel;
