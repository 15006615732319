import axios from 'axios';
require('dotenv').config();
const base_api_url = process.env.REACT_APP_API_URL;
export const Api =  axios.create({
    baseURL: base_api_url,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        // "Authorization":"Bearer 5af58567cd4990228fed126274b3ab4b769390ac1dbde073ecf5b6c0aa3feb01"
        "Authorization":"Bearer 9ee2a77e8ce49c20bfc020303ebacb58a1ccf26248862bc0726f6fbc361f8f28"
        
    }
});



