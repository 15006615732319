import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../Assets/SF_TXH_V1/SF_TXH_V1.scss"
import { VisitorParamsProvider } from "../../Contexts/VisitorParams";
import { RedirectProvider } from "../../Contexts/RedirectContext";
import * as EnvConstants from "../../constants/EnvConstants";
import { useUserAgent } from "../../Hooks/useUserAgent";
import { useRedirection } from "../../Hooks/useRedirection";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import SF_TXH_V1Header from "../Includes/SF_TXH_V1/SF_TXH_V1Header";
import SF_GNS_V1Footer from "../Includes/SF_GNS_V1/SF_GNS_V1Footer";
import YearBanner from "../Includes/SF_TXH_V1/YearBanner";
import { BannerYearAdv1 } from '../../constants/Constants';
import Anchor from "../UI/Anchor";
import { useHistory } from "react-router-dom";
import { UUID } from '../Includes/UUID';

const SF_GNS_V1 = () => {
  const queryString = useLocation().search;
  const [redirectUrl, setRedirectUrl] = useState("#");
  const [visitorParameters, setVisitorParameters] = useState({});
  const [currentMonth, setCurrentMonth] = useState("");
  const { getUserAgent } = useUserAgent();
  const { getRedirectionUrl } = useRedirection();
  const { saveDataIngestion } = useDataIngestion();
  const { getUUID, setExpiry, setParams } = UUID();
  const [region, setRegion] = useState("");
  const history = useHistory();
  setExpiry();
  let date = new Date();
  let Month = date.toLocaleString("en-us", { month: "long" });
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  const local_storage_uuid = localStorage.getItem('uuid');
  let local_storage_adv_name = localStorage.getItem('adv_name');
  var check_uuid = "";
  if (typeof query_string_arr[1] !== "undefined") {
    const query = new URLSearchParams(window.location.search);
    check_uuid = query.get("uuid");
  }
  useEffect(() => {
    setCurrentMonth(Month);
    (async () => {
      const uuids = getUUID(
        check_uuid,
        local_storage_uuid,
        local_storage_adv_name,
        '2021/MarriageTax_SF_GNS_V1'
      );
      const response = await getUserAgent(
        window.navigator.userAgent,
        "AP",
        queryString,
        EnvConstants.AppDomain,
        window.location.href,
        uuids
      );
      const visitorData = response.data.response.visitor_parameters;
      const DataParams = response.data.response.data;
      const uuid = visitorData.uuid;

      setRegion(visitorData.region)
      setParams(uuid, '2021/MarriageTax_SF_GNS_V1')

      setVisitorParameters({
        visitor_parameters: visitorData,
        data: DataParams,
        date: new Date(),
        pageName: "MarriageTax_SF_GNS_V1",
      });
      const visparam = {
        country: DataParams.country,
        device: DataParams.site_flag_id,
        siteFlagId: DataParams.site_flag,
        browser: DataParams.browser,
        platform: DataParams.platform,
        uuid: visitorData.uuid,
      };
      const responseRedirect = await getRedirectionUrl(
        visparam,
        window.location.href,
        EnvConstants.AppDomain,
        "MarriageTax_SF_GNS_V1"
      );
      await saveDataIngestion(
        "adv_page_load",
        visitorData,
        DataParams,
        "MarriageTax_SF_GNS_V1"
      );
      if (responseRedirect.data.status === "success") {
        setRedirectUrl(responseRedirect.data.response.data.redirect_url);
      } else {
        setRedirectUrl("#");
      }
    })();
  }, []);

  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <RedirectProvider value={{ redirectUrl: redirectUrl }}>
          <div className="SF_TXH_V1">
            <SF_TXH_V1Header heading="" />
            <section className="content_sec">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h1>Married Couples are Getting Cheques of up to £1,220 from the Government - Here's How to Join Them</h1>
                  </div>
                  <div className="col-lg-9 col-md-12 col-sm-12 col-12 mid_sec">
                    <div className="mr_b">
                        <Anchor dataId="PARA_IMAGE_1" className="btn_optimize ">
                        <img src="/SF_TXH_V1/img/banner.png" alt="" />
                        </Anchor>
                      <p className="ylwp"><Anchor dataId="PARA_TEXT_LINKS_1" className="ylbg "> 2.4 million couples are missing out on marriage tax allowance</Anchor></p>
                    </div>
                    <h2>Are you married or in a civil partnership?</h2>
                    <p>Did you know if you are married or in a civil partnership, you may be eligible for marriage tax allowance?  Millions of married couples are missing out on this perk every year!  But with family finances being so tight today, it’s never been more important for couples to check whether they might be eligible.</p>
                    <p>So, what is marriage tax allowance? <Anchor dataId="PARA_TEXT_LINKS_1"> <b> Marriage tax allowance</b></Anchor>  allows the lower earner in the marriage or partnership to transfer 10% of his or her personal allowance to their partner. In order to qualify for this tax break, one of the partners must earn less than £12,500 in a year. </p>
                    <p>Couples have the ability to backdate claims to include any tax year since 5 April 2016 during which they were eligible for the allowance. This could qualify couples for a lump sum, as well as a reduction in their ongoing tax bill, saving couples hundreds every year. </p>
                    <YearBanner bannerYears={BannerYearAdv1} 
                    textHead="How To Get Started:" 
                    texSubHead="SELECT YOUR YEAR OF BIRTH TO START YOUR ELIGIBILITY CHECK"
                    />
                    <p className="mt-3">Millions of married couples and civil partnerships are eligible to benefit from this allowance, yet only half of them have actually claimed their tax break.</p>
                    <h2>How do I know if I’m eligible for marriage tax allowance?</h2>
                    <ul>
                      <li>You’re married or in a civil partnership.</li>
                      <li>One of you earns less than £12,500 per year.</li>
                      <li>The other partner earns between £12,501 and £50,000 per year. </li>
                      <li>Both of you were born on or after 6 April 1935.</li>
                    </ul>
                    <h2>How Do I Claim?</h2>
                    <p>Thanks to <Anchor dataId="PARA_TEXT_LINKS_2"> <b> companies like this</b></Anchor>, applying for marriage tax allowance is simple and it only takes a few minutes. Companies like this take the hassle out of claiming by assessing your claim and handling all of the necessary paperwork needed to get you the rebate you are entitled to.</p>
                    <p>Before starting the application, it is important to know the non-taxpayer must apply in order to qualify. <Anchor dataId="PARA_TEXT_LINKS_2" className="btn ylbg"> Don’t be one of the 2.4 million couples missing out on this perk! </Anchor> </p>
                    <YearBanner bannerYears={BannerYearAdv1} 
                    textHead="How To Get Started:" 
                    texSubHead="SELECT YOUR YEAR OF BIRTH TO START YOUR ELIGIBILITY CHECK"
                    />
                    <div className="col-lg-12 text-center mapp">
                    <Anchor dataId="MAIN_CTA_BTN" className="btn main_btn ">DO I QUALIFY</Anchor>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 col-12 text-center d-none d-sm-none d-md-none d-lg-block">
                    <div className="sidebar fixedsticky">
                      <div className="rit_sec d-none d-sm-none d-md-block d-lg-block">
                        <Anchor dataId="RIGHT_SITE_BANNER_1"> <img src="/SF_TXH_V1/img/side-ban.png" alt=""/></Anchor>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
            <SF_GNS_V1Footer/>
          </div>
        </RedirectProvider>
      </VisitorParamsProvider>
    </>
  );
};
export default SF_GNS_V1;