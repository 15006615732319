import {Api} from '../api/Api';

export const useYahooGeminiUrl = () => {
    const getYahooGeminiUrl = async (visitor_parameters,full_url,domain) => {
         const response = await Api.post("api/v1/yahoo-gemini-url", {
            visitor_parameters,
            full_url,
            domain
        });
        return response;
    }
    return { getYahooGeminiUrl}
}