import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../Assets/SVY_HDR_V1/SVY_HDR_V1.scss"
import { VisitorParamsProvider } from "../../Contexts/VisitorParams";
import { RedirectProvider } from "../../Contexts/RedirectContext";
import * as EnvConstants from "../../constants/EnvConstants";
import { useUserAgent } from "../../Hooks/useUserAgent";
import { useRedirection } from "../../Hooks/useRedirection";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import SVY_HDR_V1Header from "../Includes/SVY_HDR_V1/SVY_HDR_V1Header";
import SVY_HDR_V1Footer from "../Includes/SVY_HDR_V1/SVY_HDR_V1Footer";
import Anchor from "../UI/Anchor";
import { useHistory } from "react-router-dom";
import { UUID } from '../Includes/UUID';

const SVY_HDR_V1 = () => {
  const queryString = useLocation().search;
  const [redirectUrl, setRedirectUrl] = useState("#");
  const [visitorParameters, setVisitorParameters] = useState({});
  const [currentMonth, setCurrentMonth] = useState("");
  const { getUserAgent } = useUserAgent();
  const { getRedirectionUrl } = useRedirection();
  const { saveDataIngestion } = useDataIngestion();
  const { getUUID, setExpiry, setParams } = UUID();
  const [region, setRegion] = useState("");
  const history = useHistory();
  setExpiry();
  let date = new Date();
  let Month = date.toLocaleString("en-us", { month: "long" });
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  const local_storage_uuid = localStorage.getItem('uuid');
  let local_storage_adv_name = localStorage.getItem('adv_name');
  var check_uuid = "";
  if (typeof query_string_arr[1] !== "undefined") {
    const query = new URLSearchParams(window.location.search);
    check_uuid = query.get("uuid");
  }
  useEffect(() => {
    setCurrentMonth(Month);
    (async () => {
      const uuids = getUUID(
        check_uuid,
        local_storage_uuid,
        local_storage_adv_name,
        '2021/10/HouseRepair/SVY_HDR_V1'
      );
      const response = await getUserAgent(
        window.navigator.userAgent,
        "AP",
        queryString,
        EnvConstants.AppDomain,
        window.location.href,
        uuids
      );
      const visitorData = response.data.response.visitor_parameters;
      const DataParams = response.data.response.data;
      const uuid = visitorData.uuid;

      setRegion(visitorData.region)
      setParams(uuid, '2021/10/HouseRepair/SVY_HDR_V1')

      setVisitorParameters({
        visitor_parameters: visitorData,
        data: DataParams,
        date: new Date(),
        pageName: "SVY_HDR_V1",
      });
      const visparam = {
        country: DataParams.country,
        device: DataParams.site_flag_id,
        siteFlagId: DataParams.site_flag,
        browser: DataParams.browser,
        platform: DataParams.platform,
        uuid: visitorData.uuid,
      };
      const responseRedirect = await getRedirectionUrl(
        visparam,
        window.location.href,
        EnvConstants.AppDomain,
        "SVY_HDR_V1"
      );
      await saveDataIngestion(
        "adv_page_load",
        visitorData,
        DataParams,
        "SVY_HDR_V1"
      );
      if (responseRedirect.data.status === "success") {
        setRedirectUrl(responseRedirect.data.response.data.redirect_url);
      } else {
        setRedirectUrl("#");
      }
    })();
  }, []);

  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <RedirectProvider value={{ redirectUrl: redirectUrl }}>
            <SVY_HDR_V1Header heading="" />
            <section className="SVY_HDR_V1_section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h1>ATTENTION: If You Are Renting A Home in Disrepair You Could Claim £1,000’s in Compensation!</h1>
                    <h3>Find Out How to Get Started Today! </h3>
                    <Anchor dataId="PARA_IMAGE_1"> 
                      <img src="/SVY_HDR_V1/img/banner.jpg" alt="" className="banner_image"/> 
                    </Anchor>
                    <p class="banner_txt">
                      <span>
                        <Anchor>
                          <u>
                              The Independent reported more than half a million homes in England do 
                              not meet basic health and safety standards.<sup>1</sup>               
                          </u>
                        </Anchor>
                      </span>
                    </p>
                    <p>If you’re one of the thousands of families living in poor conditions you could be eligible to claim compensation.</p>

                    <p>
                      Landlords have a legal obligation to fix ALL housing disrepair issues ensuring tenants are 
                      living in a safe and well-maintained home. If you’ve made a complaint to your landlord about 
                      your home being in disrepair and it hasn’t been addressed,
                      <Anchor><strong><u>you could be owed £100s or even £1,000s</u></strong></Anchor>.
                    </p>

                    <h3>
                      <strong>
                        Are You Eligible to Make A Claim?
                      </strong>
                    </h3>

                    <ul>
                      <li> You are a Council Housing Tenant</li>
                      <li> You are a Housing Association Tenant</li>
                      <li> You've complained to your landlord about your issues but they haven't been resolved</li>
                    </ul>

                    <p>
                     Making a claim will allow you to receive compensation for the duration of time the 
                     problem has existed and speed up the process of repairs. <Anchor><strong><u>Start your claim today!</u></strong></Anchor>
                    </p>

                    <h3>
                      <strong>
                          How Can I Start A Claim For Faster Repairs and Compensation? 
                      </strong>
                    </h3>

                    <p>
                      Finding out if you’re eligible to claim can be tricky so
                        this company has created a FREE online checking service to make it quick and easy. 
                    </p>

                    <p className="mb-0">
                      <strong>Step 1:</strong> Select the type of issues you want fixed below.
                    </p>
                    <p>
                      <strong>Step 2:</strong> Complete the online form and get connected with an 
                      expert that will discuss your eligibility to claim and assist you through the entire claims process.

                    </p>
                  </div>

                  <div class="col-lg-12 col-12  banner text-center">
                    <div class="mid-banner">
                      <div class="midinnr">
                        <div class="triangle1"></div>
                        <h2> SELECT THE ISSUES IN NEED OF REPAIR </h2>
                        <a dataId="MID_PARA_NUM" className="btn_1">
                          <img src="/SVY_HDR_V1/img/icon-1.png" alt=""/><br/>
                          Rising Damp
                        </a>
                        <a dataId="MID_PARA_NUM" className="btn_2">
                          <img src="/SVY_HDR_V1/img/icon-2.png" alt=""/><br/>
                          Mould / Condensation
                        </a>
                        <Anchor dataId="MID_PARA_NUM" className="btn_3">
                          <img src="/SVY_HDR_V1/img/icon-3.png" alt=""/><br/>
                          Leaking Water
                        </Anchor>
                        <Anchor dataId="MID_PARA_NUM" className="btn_4">
                          <img src="/SVY_HDR_V1/img/icon-4.png" alt=""/><br/>
                          Missing / Loose Tiles
                        </Anchor>
                        <Anchor dataId="MID_PARA_NUM" className="btn_5">
                          <img src="/SVY_HDR_V1/img/icon-5.png" alt=""/><br/>
                          Brickwork Repointing
                        </Anchor>
                        <Anchor dataId="MID_PARA_NUM" className="btn_6">
                          <img src="/SVY_HDR_V1/img/icon-6.png" alt=""/><br/>
                          Structural Cracks
                        </Anchor>
                        <Anchor dataId="MID_PARA_NUM" className="btn_7">
                          <img src="/SVY_HDR_V1/img/icon-7.png" alt=""/><br/>
                          Insect & Vermin Infestation
                        </Anchor>
                        <Anchor dataId="MID_PARA_NUM" className="btn_8">
                          <img src="/SVY_HDR_V1/img/icon-8.png" alt=""/><br/>
                          Poor Ventilation
                        </Anchor>
                        <Anchor dataId="MID_PARA_NUM" className="btn_9">
                          <img src="/SVY_HDR_V1/img/icon-9.png" alt=""/><br/>
                          Boiler / Central Heating Issues
                        </Anchor>
                        <Anchor dataId="MID_PARA_NUM" className="btn_10">
                          <img src="/SVY_HDR_V1/img/icon-10.png" alt=""/><br/>
                          Asbestos
                        </Anchor>
                        <Anchor dataId="MID_PARA_NUM" className="btn_11">
                          <img src="/SVY_HDR_V1/img/icon-11.png" alt=""/><br/>
                          No Running or Hot Water
                        </Anchor>
                        <Anchor dataId="MID_PARA_NUM" className="btn_12">
                          <img src="/SVY_HDR_V1/img/icon-12.png" alt=""/><br/>
                          Electrical Issues
                        </Anchor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <SVY_HDR_V1Footer/>
        </RedirectProvider>
      </VisitorParamsProvider>
    </>
  );
};
export default SVY_HDR_V1;