import React,{useContext} from 'react';
// import QueryStringContext from "../../Contexts/QueryString";
import {getParameterByName} from '../../Utility/Helper';
import ReactPixel from 'react-facebook-pixel';
const FbViewPixel = () =>{

const full_url = window.location.href;
const query_string_arr = full_url.split("?");
var queryString = "";
if(query_string_arr!=null)
{
        queryString = query_string_arr[1];
}
const field = 'pid';
if(queryString!=null)
{
    if(queryString.indexOf('&' + field + '=') !== -1)
    {
        const pid = getParameterByName(field,queryString);
        const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
        };
        ReactPixel.init(pid, advancedMatching, options);

        ReactPixel.pageView(); 
        // For tracking page view
    // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
    // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
    }

}
return (
    <>
    </>
    )
    
}
export default FbViewPixel;