import React from 'react';
import Anchor from "../../UI/Anchor";

const SF_TXH_YG_V1Header     =   ({heading,Style}) =>{
    const HeadString =  (heading)? heading: 'Advertorial';
    return (
        <>
         <div className="col-lg-12 d-none d-sm-block d-md-block d-lg-block adv_head text-center">
         <p>{HeadString}</p>
          </div>
           <header className="SF_Header">
         <div className="container">
            <div className="row">
               <div className="col-lg-3 col-md-4 col-sm-12 col-6 logo">
                  <Anchor dataId="SITE_LOGO" className="btn_optimize"> 
                  <img src="/SF_TXH_YG_V1/img/logo.png" alt="Logo"/> 
                  </Anchor>
               </div>
               <div className="col-lg-9 col-md-8 col-sm-12 col-6 top_bnr">
                  <Anchor dataId="TOP_SITE_BANNER" className="d-none d-sm-none d-md-block d-lg-block"> 
                  <img src="/SF_TXH_YG_V1/img/right_hed.jpg" alt=""/>
                  </Anchor>
                  <div className="col-lg-12 d-block d-sm-none d-md-none d-lg-none adv_head text-center">
                     <p className="mt-3 text-right">{HeadString}</p>
                  </div>
               </div>
            </div>
         </div>
      </header>
    </>
    )
}
export default SF_TXH_YG_V1Header;