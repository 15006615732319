import React from "react";
import Anchor from "../../UI/Anchor";
const SVS_GLI_ADV1Header = ({arrow,logo }) => {
    return (
        <>
            <div className="container margin-12 py-2 ">
                <div className="row row_margin">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-5 margin-12 brand-logo ">
                    <Anchor dataId="LOGO_TEXT_LINKS_2">
                            <img src={logo} alt="" className="img-fluid mt-2 img-responsive " />
                            </Anchor>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6 col-7 margin-12 mbl-cen">
                    <Anchor dataId="HEADER_TEXT_LINKS_2" className="btn btn-primary btn-lg font-weight-bold rounded click-here"> Check Your Eligibility
                            <img src={arrow} alt="" className="ml-1" />
                            </Anchor>
                    </div>

                </div>
            </div>
        </>
    )
}
export default SVS_GLI_ADV1Header;