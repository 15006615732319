const GoogleTag = () =>{

//google tag manager script


return (
    <>
    </>
    )
    
}



export default GoogleTag;