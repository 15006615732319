const Matomo = () => {
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(["setCookieDomain", "*.savvyfinances.co.uk"]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.spicy-tees.in/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '35']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
    <noscript><p><img src="https://matomo.spicy-tees.in/matomo.php?idsite=31&rec=1" style={{ border: 0 }} alt="" /></p></noscript>
  return (
    <>
    </>
  )

}

export default Matomo;