import React, { useState } from 'react';


const ADV_GNS_TXH_V1Footer     =   () =>{
    const [showPrivacy,setShowPrivacy] = useState('hide');
    const [showCookie,setShowCookie] = useState('hide');
    const modalClick = (clickValue) =>{
        var modalAction =  clickValue;
          switch(modalAction){
              case'showPrivacy':{
                setShowPrivacy('show');
                document.body.classList.add('modal-open');
                break;
              }
              case'exitPrivacy':{
                setShowPrivacy('hide');
                document.body.classList.remove('modal-open');
                break;
              }
              case'showCookie':{
                setShowCookie('show');
                document.body.classList.add('modal-open');
                break;
              }
              case'exitCookie':{
                setShowCookie('hide');
                document.body.classList.remove('modal-open');
                break;
              }
          }
    }
    return (
        <>
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 text-center">
                        <img src="/ADV_SQL_TXH_V1/logo.png" className="animated  bounceInDown"  alt="savvyfinances"/>
                               <p>
                                DISCLAIMER: The material on this website is intended only to provide a summary and
                                general overview on matters of interest.
                            </p>

                            <ul>
                                <li>
                                    <a className="rp_clk" data-toggle="modal"
                                        onClick={()=>modalClick('showPrivacy')}>Privacy Policy</a>
                                </li>
                                <li>
                                    <a className="rp_clk" data-toggle="modal"
                                        onClick={()=>modalClick('showCookie')}>Cookies</a>
                                </li>
                                <li>
                                    <a className="rp_clk"
                                       href="/MarriageTax/ADV_SQL_GNS-_V1/contact-us" target="_blank"> Contact Us</a>
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
        </footer>
            <div className={`modal fade bs-example-modal-lg-Privacy ${showPrivacy}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Privacy Policy</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={()=>modalClick('exitPrivacy')}>×</button>
                        </div>
                        <div className="modal-body">
                            <p>Last updated: 25 April 2021</p>
                            <p>Our company The Savvy Finances ("us", "we", or "our") operates thesavvyfinances.co.uk
                                (the "Site"). This page informs you of our policies regarding the collection, use and
                                disclosure of Personal Information we receive from users of the Site.</p>
                            <p>By using the Site, you agree to the collection and use of information in accordance with
                                this policy.</p>
                            <h5>Information Collection, Retention and Use</h5>
                            <p>
                                While using our Site, we may ask you to provide us with certain personally identifiable
                                information that may be used to offer you services of interests to you. Personally
                                identifiable information may include, but is not limited to your age, location
                                ("Personal Information"). We may use the information to customize the website according
                                to your interests and improving the Site and provide a summary and general overview on
                                matters of interest. When you submit your information your personal data is retained for
                                no longer than is necessary.
                            </p>
                            <h5>Sharing information with third parties.</h5>
                            <p>
                                We will not sell, distribute or lease your personal information to third parties unless
                                we have your permission or are required by law to do so. We may need to pass your
                                information to third parties who help us to maintain, administer or develop the website
                                but, if so, that information is only given to those third parties for that limited
                                purpose and subject to those third parties agreeing to keep the information secure.
                            </p>
                            <h5>Log Data</h5>
                            <p>Like many site operators, we collect information that your browser sends whenever you
                                visit our Site ("Log Data").</p>
                            <p>This Log Data may include information such as your computer's Internet Protocol ("IP")
                                address, browser type, browser version, the pages of our Site that you visit, the time
                                and date of your visit, the time spent on those pages and other statistics.</p>
                            <h5>Cookies</h5>
                            <p>Cookies are files with small amount of data, which may include an anonymous unique
                                identifier. Cookies are sent to your browser from a web site and stored on your
                                computer's hard drive.</p>
                            <p>Like many sites, we use "cookies" to collect information. You can instruct your browser
                                to refuse all cookies or to indicate when a cookie is being sent. However, if you do not
                                accept cookies, you may not be able to use some portions of our Site.</p>

                            <h5>Security</h5>
                            <p>The security of your Personal Information is important to us, but remember that no method
                                of transmission over the Internet, or method of electronic storage, is 100% secure.
                                While we strive to use commercially acceptable means to protect your Personal
                                Information, we cannot guarantee its absolute security.</p>
                            <h5>Links to other websites</h5>
                            <p>Our website may contain links to other websites of interest. However, once you have used
                                these links to leave our site, you should note that we do not have any control over that
                                other website. Therefore, we cannot be responsible for the protection and privacy of any
                                information which you provide whilst visiting such sites and such sites are not governed
                                by this privacy statement. You should exercise caution and look at the privacy statement
                                applicable to the website in question.</p>
                            <h5>International data transfers</h5>
                            <p>Information that we collect may be stored and processed in and transferred between any of
                                the countries in which we operate in order to enable us to use the information in
                                accordance with this policy.</p>
                            <p>Information that we collect may be transferred to the following countries which do not
                                have data protection laws equivalent to those in force in the European Economic Area:
                                [the United States of America and India].</p>
                            <p>Personal information that you publish on our website or submit for publication on our
                                website may be available, via the internet, around the world. We cannot prevent the use
                                or misuse of such information by others.</p>
                            <p>You expressly agree to the transfers of personal information described in this
                                Section</p>
                            <h5>Your consent and your rights</h5>
                            <p>You acknowledge and agree that in the course of using this website and any services
                                offered via this website, certain information about you will be captured electronically
                                (sensitive personal data where required, but this will be subject to you giving your
                                explicit consent in such cases) or otherwise and transmitted to us or and, potentially,
                                to any third parties as set out above, or to third parties who host our web servers or
                                assist us in maintaining this website.</p>
                            <h5>Under the GDPR you have the following rights:</h5>
                            <p>To know that your data is being processed and to make a ‘Subject Access Request’</p>
                            <p>To access your personal data free of charge</p>
                            <p>To data portability without undue delay</p>
                            <p>To have your information corrected if inaccurate (Including ensuring any third party puts
                                right any inaccuracy)</p>
                            <p>To request that your data be erased</p>
                            <p>To restrict processing</p>
                            <p>To object to processing and;</p>
                            <p>To rights relating to automated decision making and profiling</p>
                            <p>You have a legal right to see a copy of the information we hold about you, this is
                                referred to as a Subject Access Request or SAR. When you request a SAR it is provided
                                free of charge and within 30 days however, we will need to ask for identification and
                                where applicable authority to release information to a person appointed to act on your
                                behalf.</p>
                            <p>You also have the right to correct any errors in the information that we hold about you.
                                Please let us know about changes to your details so that we can keep our records
                                accurate and up to date.</p>
                            <p>Users may exercise these rights or if they have any complaints by contacting The Savvy
                                Finances in the following ways: (i) by sending a registered letter to The Daily
                                Reporter, (ii) by sending an email to info@thesavvyfinances.co.uk</p>
                            <p>If you are not happy with how we process your personal information you should contact us
                                in the first instance. If you’re not happy with how we have dealt with your complaint
                                you have the right to lodge a complaint with the Information Commissioner’s Office. You
                                can find their details on their website at https://ico.org.uk/</p>
                            <h5>Changes To This Privacy Policy</h5>
                            <p>This Privacy Policy is effective as of 25 April 2021 and will remain in effect except
                                with respect to any changes in its provisions in the future, which will be in effect
                                immediately after being posted on this page.</p>
                            <p>We reserve the right to update or change our Privacy Policy at any time and you should
                                check this Privacy Policy periodically. Your continued use of the Service after we post
                                any modifications to the Privacy Policy on this page will constitute your acknowledgment
                                of the modifications and your consent to abide and be bound by the modified Privacy
                                Policy.</p>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-default closer" data-dismiss="modal" onClick={()=>modalClick('exitPrivacy')}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade bs-example-modal-lg-Cookies ${showCookie}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Cookies Policy</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={()=>modalClick('exitCookie')}>×</button>
                        </div>
                        <div className="modal-body">
                            <p>This website uses cookies to better the users experience while visiting the website.
                                Where applicable this website uses a cookie control system allowing the user on their
                                first visit to the website to allow or disallow the use of cookies on their computer /
                                device. This complies with recent legislation requirements for website’s to obtain
                                explicit consent from users before leaving behind or reading files such as cookies on a
                                users computer / device.</p>
                            <p>Cookies are small files saved to the users computers hard drive that track, save and
                                store information about the users interactions and usage of the website. This allows the
                                website, through it’s server to provide the users with a tailored experience within this
                                website.</p>
                            <p>Users are advised that if they wish to deny the use and saving of cookies from this
                                website on to their computers hard drive they should take necessary steps within their
                                web browsers security settings to block all cookies from this website and it’s external
                                serving vendors.</p>
                            <p>This website uses tracking software to monitor it’s visitors to better understand how
                                they use it. This software is provided by Google Analytics which uses cookies to track
                                visitor usage. The software will save a cookie to your computers hard drive in order to
                                track and monitor your engagement and usage of the website but will not store, save or
                                collect personal information. You can read Google’s privacy policy here for further
                                information [http://www.google.com/privacy.html].</p>
                            <p>Other cookies may be stored to your computers hard drive by external vendors when this
                                website uses referral programs, sponsored links or adverts. Such cookies are used for
                                conversion and referral tracking and typically expire after 30 days, though some may
                                take longer. No personal information is stored, saved or collected.</p>
                            <h5> HOW WE USE COOKIES</h5>
                            <p>A cookie is a small file which asks permission to be placed on your computer’s hard
                                drive. Once you agree, the file is added and the cookie helps analyse web traffic or
                                lets you know when you visit a particular site. Cookies allow web applications to
                                respond to you as an individual. The web application can tailor its operations to your
                                needs, likes and dislikes by gathering and remembering information about your
                                preferences.</p>
                            <p>We use traffic log cookies to identify which pages are being used. This helps us analyse
                                data about web page traffic and improve our website in order to tailor it to customer
                                needs. We only use this information for statistical analysis purposes and then the data
                                is removed from the system.</p>
                            <p>Overall, cookies help us provide you with a better website, by enabling us to monitor
                                which pages you find useful and which you do not. A cookie in no way gives us access to
                                your computer or any information about you, other than the data you choose to share with
                                us. </p>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-default closer" data-dismiss="modal" onClick={()=>modalClick('exitCookie')}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
         </>
    )
}
export default ADV_GNS_TXH_V1Footer;
