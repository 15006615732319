import React from 'react';
import Anchor from "../../UI/Anchor";
const ADV_SQL_TXH_V1Header     =   ({heading,textContent}) =>{
    const HeadString =  (heading)? heading: 'Advertorial';
    return (
        <>
            <div className="container">
                <div className="row">

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 top_head">
                        <p>{HeadString}</p>
                        <h1>Are You Missing Out on A Marriage Tax Refund? </h1>
                        <h5>If Yes, You Could Be Owed up to £1,220!</h5>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-5 left_bg">
                        <img src="/ADV_SQL_TXH_V1/young.png" alt=""/>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-7 one_bg">
                        <Anchor
                            dataId="Continue"
                            className="btn blue_btn btn_optimize"
                        >
                            Check Eligibility >
                        </Anchor>
                        <h3 className="text-center d-none d-lg-block">{textContent}</h3> 
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-lg-none d-sm-blocks img_btm">
                        {/* <h3 className="text-center">Highest Refund: £22,438.50*</h3> */}
                    </div>
                </div>
            </div>
    </>
    )
}
export default ADV_SQL_TXH_V1Header;