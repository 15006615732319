import React from "react";
import { useState, useEffect } from "react";
import "../../Assets/SVS_GLI_ADV1/SVS_GLI_ADV1.scss";
import { useUserAgent } from "../../Hooks/useUserAgent";
import { useLocation } from "react-router-dom";
import { useRedirection } from "../../Hooks/useRedirection";
import { RedirectProvider } from "../../Contexts/RedirectContext";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { UUID } from '../Includes/UUID';
import { VisitorParamsProvider } from "../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import Anchor from "../UI/Anchor";
import * as EnvConstants from "../../constants/EnvConstants";
import logo from "../../Assets/SVS_GLI_ADV1/img/logo.svg";
import arrow from "../../Assets/SVS_GLI_ADV1/img/arrow.svg";
import SavvySamMaleImg from "../../Assets/SVS_GLI_ADV1/img/SavvySamMaleImg1.jpg"
import image01 from "../../Assets/SVS_GLI_ADV1/img/20-29.png";
import image02 from "../../Assets/SVS_GLI_ADV1/img/30-39.png";
import image03 from "../../Assets/SVS_GLI_ADV1/img/40-49.png";
import image04 from "../../Assets/SVS_GLI_ADV1/img/50-59.png";
import image05 from "../../Assets/SVS_GLI_ADV1/img/60.png";
import age_banner from "../../Assets/SVS_GLI_ADV1/img/age-banner.png";
import SVS_GLI_ADV1Header from "../Includes/SVS_GLI_ADV1/SVS_GLI_ADV1Header";
import SVS_GLI_ADV1Footer from "../Includes/SVS_GLI_ADV1/SVS_GLI_ADV1Footer";
const SVS_GLI_Male_ADV2 = () => {
    const queryString = useLocation().search;
    const [redirectUrl, setRedirectUrl] = useState("#");
    const [visitorParameters, setVisitorParameters] = useState({});
    const [currentMonth, setCurrentMonth] = useState("");
    const { getUserAgent } = useUserAgent();
    const { getRedirectionUrl } = useRedirection();
    const { saveDataIngestion } = useDataIngestion();
    const { getUUID, setExpiry,setParams } = UUID();
    const [region, setRegion] = useState("");
    const history = useHistory();
    setExpiry();
    let date = new Date();
    let Month = date.toLocaleString("en-us", { month: "long" });
    const full_url = window.location.href;
    const query_string_arr = full_url.split("?");
    const local_storage_uuid = localStorage.getItem('uuid');
    let local_storage_adv_name = localStorage.getItem('adv_name');
    var check_uuid = "";
    if (typeof query_string_arr[1] !== "undefined") {
      const query = new URLSearchParams(window.location.search);
      check_uuid = query.get("uuid");
    }
    useEffect(() => {
      setCurrentMonth(Month);
      (async () => {
        const uuids = getUUID(
          check_uuid,
          local_storage_uuid,
          local_storage_adv_name,
          'SVS_GLI_ADV1'
        );
        const response = await getUserAgent(
          window.navigator.userAgent,
          "AP",
          queryString,
          EnvConstants.AppDomain,
          window.location.href,
          uuids
        );
        const visitorData = response.data.response.visitor_parameters;
        const DataParams = response.data.response.data;
        const uuid = visitorData.uuid;
  
        setRegion(visitorData.region)
        setParams(uuid,'SVS_GLI_ADV1')
  
        setVisitorParameters({
          visitor_parameters: visitorData,
          data: DataParams,
          date: new Date(),
          pageName: "SVS_GLI_ADV1",
        });
        const visparam = {
          country: DataParams.country,
          device: DataParams.site_flag_id,
          siteFlagId: DataParams.site_flag,
          browser: DataParams.browser,
          platform: DataParams.platform,
          uuid: visitorData.uuid,
        };
        const responseRedirect = await getRedirectionUrl(
          visparam,
          window.location.href,
          EnvConstants.AppDomain,
          "SVS_GLI_ADV1"
        );
        await saveDataIngestion(
          "adv_page_load",
          visitorData,
          DataParams,
          "SVS_GLI_ADV1"
        );
        if (responseRedirect.data.status === "success") {
          setRedirectUrl(responseRedirect.data.response.data.redirect_url);
        } else {
          setRedirectUrl("#");
        }
      })();
    }, []);
  
    return (
        <>
        <VisitorParamsProvider value={{ visitorParameters }}>
            <RedirectProvider value={{ redirectUrl: redirectUrl }}>
                <div className="SVS_GLI_ADV1">
                    <div className="webPage">
                        <header>
                            <SVS_GLI_ADV1Header
                            arrow={arrow}
                            logo={logo}
                            />
                        </header>
                        <section>
                            <div className="container margin-12">
                                <div className="content">
                                    <div className="row row_margin">
                                        <div className="col-xl-9 col-lg-9 margin-12 lft_sec">
                                            <h2 className="big-font">
                                                <b>THIS JUST IN: UK Residents Can Now Lock In A £100,000* Payment For Their Loved Ones When They Die.<br className="d-none d-lg-block" />
                                                </b>
                                            </h2>
                                            <h2 className="sub_title">Thousands are jumping at this opportunity to help protect their family's financial security for the future.</h2>
                                            <Anchor dataId="PARA_IMAGE_1">
                                                <img className="image-responsive" src={SavvySamMaleImg} alt="" width="100%" />
                                                </Anchor>

                                            <p className="yellow_link mt-2"><span>
                                            <Anchor dataId="PARA_TEXT_LINKS_1">
                                                    Find out if you are eligible.
                                                    </Anchor>
                                                    </span></p>
                                            <p className="mt-3">Did you know UK residents can now get approved for a £100,000 life insurance policy to help provide a lump-sum for family and loved ones? This opportunity to help ensure your family's financial security won't last forever, so <strong>
                                            <Anchor dataId="PARA_TEXT_LINKS_2">
                                                  <span className="txt_underline"> act fast to check your eligibility and secure your rates now.</span> 
                                                    </Anchor>
                                                </strong></p>

                                            <div className="col-12 margin-12 pb-3 btn_sec text-center">
                                                <p>It only takes a few seconds to find out if you qualify. Tap below to get started. ⬇️</p>
                                                <h3>
                                                    <b>Are You A UK Resident?</b>
                                                </h3>
                                                <Anchor dataId="PARA_BTN_LINKS_1">
                                                    <a href="#">
                                                        <button className="btn btn-success btn-custom btn-green">
                                                            <i className="fa fa-check-circle fa-custom"></i> Yes
                                                        </button>
                                                    </a>
                                                    </Anchor>
                                                    <Anchor dataId="PARA_BTN_LINKS_1">
                                                    <a href="#">
                                                        <button className="btn btn-danger btn-custom btn-red">
                                                            <i className="fa fa-ban fa-custom"></i> No
                                                        </button>
                                                    </a>
                                                    </Anchor>
                                            </div>

                                            <p>
                                                Getting a life insurance quote should be quick and easy, but many find it difficult to secure a policy that will protect their family.
                                            </p>

                                            <div className="col-12 margin-12 text-center d-block d-sm-block d-md-block d-lg-none d-xl-none mb-3">
                                            <Anchor dataId="AGE_BANNER_LINKS_1">
                                                    <img src={age_banner} alt="" className="w-100 tabwidth" />
                                                    </Anchor>
                                            </div>

                                            <p>
                                                Don't spend hours searching for the best policy from multiple providers.   Follow the easy steps below to get a FREE personalised quote built for your family and your needs.
                                            </p>


                                            <h3 className="mb-3">Here's How to Get Started Today</h3>
                                            <p><strong>Step 1:</strong> Use our simple form to answer a few questions (it takes just two minutes)</p>
                                            <p><strong>Step 2:</strong> Once we have your details, we'll get you talking to a consultant.</p>


                                            <h3 className="mb-4 text-center">Select Your Age NOW!</h3>

                                            <div className="col-xl-12 col-lg-12 margin-12 text-center age_btn">
                                                <div className="row m-0 justify-content-center row_margin">
                                                <Anchor dataId="AGE_BTN_LINKS_1" className="btn age_btns" >
                                                        <img src={image01} alt="" />
                                                        <br />
                                                        <span>20 - 29</span>
                                                        </Anchor>
                                                        <Anchor dataId="AGE_BTN_LINKS_1" className="btn age_btns" >
                                                        <img src={image02} alt="" />
                                                        <br />
                                                        <span>30 - 39</span>
                                                        </Anchor>
                                                    <Anchor dataId="AGE_BTN_LINKS_1" className="btn age_btns" >
                                                        <img src={image03} alt="" />
                                                        <br />
                                                        <span>40 - 49</span>
                                                        </Anchor>
                                                    <Anchor dataId="AGE_BTN_LINKS_1" className="btn age_btns" >
                                                        <img src={image04} alt="" />
                                                        <br />
                                                        <span>50 - 59</span>
                                                        </Anchor>
                                                    <Anchor dataId="AGE_BTN_LINKS_1" className="btn age_btns" >
                                                        <img src={image05} alt="" />
                                                        <br />
                                                        <span>60 +</span>
                                                        </Anchor>
                                                </div>
                                                <Anchor dataId="MAIN_BTN_LINKS_1" className="btn main_btn pulse">Get Your FREE Life Insurance Quotes</Anchor>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 margin-12 text-center d-none d-sm-none d-md-none d-lg-block d-xl-block">
                                            <div className="sidebar fixedsticky">
                                            <Anchor dataId="AGE_BANNER_LINKS_1">
                                                    <img src={age_banner} alt="" className="w-100" />
                                                    </Anchor>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <footer>
                            <SVS_GLI_ADV1Footer
                            logo={logo}
                            />
                        </footer>  
                    </div>
                </div>
            </RedirectProvider>
      </VisitorParamsProvider>
        </>
    )
}
export default SVS_GLI_Male_ADV2;