import {Api} from '../api/Api';

export const useRedirection = () => {
    const getRedirectionUrl = async (visitor_parameters,full_url,domain,page_name) => {
         const response = await Api.post("api/v1/redirection_lp", {
            visitor_parameters,
            full_url,
            domain,
            page_name
        });
        return response;
    }
    return { getRedirectionUrl}
}