import {Api} from '../api/Api';

export const useDataIngestion = () => {
    const saveDataIngestion = async (message_type,visitor_parameters,data,page_name) => {
         const response = await Api.post("api/v1/data-ingestion-pipeline", {
            message_type,
            visitor_parameters,
            data,
            page_name
        });
        return response;
    }
    return { saveDataIngestion}
}