import React from 'react';
import Anchor from "../../UI/Anchor";

const imgStyles = {

};
const YearBanner = ({ textHead, texSubHead, bannerYears }) => {
  const textHeadDefault = (textHead) ? textHead : "How To Get Started";
  const Years = bannerYears.map((year, index) => {
    return (
      <Anchor dataId="MID_PARA_NUM" key={index}>
        {year}
      </Anchor>
    );
  });
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div className="col-lg-12 d-block d-sm-block d-md-none d-lg-none mob_bnr p-0">
          <div className="flasher d-block d-sm-none d-md-none d-lg-none">
            <h2>{textHead}</h2>
            <h6>{texSubHead}</h6>
            <div className="age_brdr">
              <div className="arow">
                <img src="/SF_TXH_V1/img/btm_arow.png" alt="" />
              </div>
              <div id="blink-wrapper">
                {Years}
              </div>
            </div>
          </div>
        </div>
        <div className="banner d-none d-sm-block d-md-block d-lg-block">
          <h2>{textHead}</h2>
          <h6>{texSubHead}</h6>
          <div className="age_brdr">
            <div className="arow">
              <img src="/SF_TXH_V1/img/btm_arow.png" alt="" />
            </div>
            <div id="blink-wrapper">
              {Years}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default YearBanner;