import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import  "../../Assets/ADV_SQL_TXH_V1/ADV_SQL_TXH_V1.scss"
import { VisitorParamsProvider } from "../../Contexts/VisitorParams";
import { RedirectProvider } from "../../Contexts/RedirectContext";
import * as EnvConstants from "../../constants/EnvConstants";
import { useUserAgent } from "../../Hooks/useUserAgent";
import { useRedirection } from "../../Hooks/useRedirection";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import ADV_SQL_TXH_V1Header from "../Includes/ADV_SQL_TXH_V1/ADV_SQL_TXH_V1Header";
import ADV_SQL_TXH_V1Footer from "../Includes/ADV_SQL_TXH_V1/ADV_SQL_TXH_V1Footer";
import { useHistory } from "react-router-dom";
import { UUID } from '../Includes/UUID';

const ADV_SQL_TXH_V1 = () => {
  const queryString = useLocation().search;
  const [redirectUrl, setRedirectUrl] = useState("#");
  const [visitorParameters, setVisitorParameters] = useState({});
  const [currentMonth, setCurrentMonth] = useState("");
  const { getUserAgent } = useUserAgent();
  const { getRedirectionUrl } = useRedirection();
  const { saveDataIngestion } = useDataIngestion();
  const { getUUID, setExpiry,setParams } = UUID();
  const [region, setRegion] = useState("");
  const history = useHistory();
  setExpiry();
  let date = new Date();
  let Month = date.toLocaleString("en-us", { month: "long" });
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  const local_storage_uuid = localStorage.getItem('uuid');
  let local_storage_adv_name = localStorage.getItem('adv_name');
  var check_uuid = "";
  if (typeof query_string_arr[1] !== "undefined") {
    const query = new URLSearchParams(window.location.search);
    check_uuid = query.get("uuid");
  }
  useEffect(() => {
    setCurrentMonth(Month);
    (async () => {
      const uuids = getUUID(
        check_uuid,
        local_storage_uuid,
        local_storage_adv_name,
        'MarriageTax/ADV_SQL-TXH_V1'
      );
      const response = await getUserAgent(
        window.navigator.userAgent,
        "AP",
        queryString,
        EnvConstants.AppDomain,
        window.location.href,
        uuids
      );
      const visitorData = response.data.response.visitor_parameters;
      const DataParams = response.data.response.data;
      const uuid = visitorData.uuid;

      setRegion(visitorData.region)
      setParams(uuid,'MarriageTax/ADV_SQL-TXH_V1')

      setVisitorParameters({
        visitor_parameters: visitorData,
        data: DataParams,
        date: new Date(),
        pageName: "ADV_SQL_TXH_V1",
      });
      const visparam = {
        country: DataParams.country,
        device: DataParams.site_flag_id,
        siteFlagId: DataParams.site_flag,
        browser: DataParams.browser,
        platform: DataParams.platform,
        uuid: visitorData.uuid,
      };
      const responseRedirect = await getRedirectionUrl(
        visparam,
        window.location.href,
        EnvConstants.AppDomain,
        "ADV_SQL_TXH_V1"
      );
      await saveDataIngestion(
        "adv_page_load",
        visitorData,
        DataParams,
        "ADV_SQL_TXH_V1"
      );
      if (responseRedirect.data.status === "success") {
        setRedirectUrl(responseRedirect.data.response.data.redirect_url);
      } else {
        setRedirectUrl("#");
      }
    })();
  }, []);

  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <RedirectProvider value={{ redirectUrl: redirectUrl }}>
          <div className="ADV_SQL_TXH_V1">
           <header>
             <ADV_SQL_TXH_V1Header></ADV_SQL_TXH_V1Header>
           </header>
          <ADV_SQL_TXH_V1Footer></ADV_SQL_TXH_V1Footer>
          </div>
        </RedirectProvider>
      </VisitorParamsProvider>
    </>
  );
};
export default ADV_SQL_TXH_V1;